body {
    --primary-turquoise: #08d9d6;
    --secondary-black: #252a34;
    --third-red: #ff2e63;
    --third-grey: #eaeaea;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--primary-turquoise);
}
.turquoise {
    color: var(--primary-turquoise);
}
.btn-danger {
    background-color: var(--third-red);
}
.btn-light {
    background-color: var(--third-grey);
    color: var(--secondary-black);
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@font-face {
    font-family: "card-font";
    src: local("card-font"), url(./fonts/card-font.TTF) format("ttf");
}
.card div.col {
    padding-left: 0;
    padding-right: 0;
}